import './output.css';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert'
import {Accordion } from 'react-bootstrap'

import { Box, HeaderBox, LinkBox } from './components'


class AccordionItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Accordion.Item eventKey={this.props.item.id}>
        <Accordion.Header>{ this.props.item.header }</Accordion.Header>
        <Accordion.Body>
          {this.render_img()}
          <br></br>
          {this.render_txt()}
          <br></br>
          {this.render_link()}
        </Accordion.Body>
      </Accordion.Item>
    );
  }

  render_img = () => {

    if (!this.props.item.img) return;

    return (
    <div Style="text-align: center;">
      <img alt={this.props.item_img} src={this.props.item.img} className="img"/>
      <br></br>
    </div>
    );
  }

  render_txt = () => {
     return (
      <div>
      {this.props.item.blurb}
      </div>
     )
  }

  render_link = () =>
    <div>
      <a
      href={this.props.item.linkto}
      target="_blank"
      rel="noreferrer noopener">
      Click here for more information
      </a>
    </div>
}




class AccordionWrap extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let accordion_items = [];
    let p = this.props.points;
    for(let i=0; i<p.length; ++i) {
      if (this.props.inRange(p[i]))
        accordion_items.push(<AccordionItem item={RES[MAPPING[i]]} />);
    }

    return <Box render_object={this.r(accordion_items)} />;
  }

  r = (items) => {
    return (
    <div className="questionbox">
      <Alert variant={this.props.variant}>
        <Alert.Heading><b>{this.props.title}</b></Alert.Heading>
        <hr />
        <Alert.Heading>
          {this.props.body}
        </Alert.Heading>
      </Alert>

      <Accordion>
        { items }
      </Accordion>
    </div>
    );
  }
}



// Indices to result..
// This matches pointing arrays
// Yes, there are better ways about this :P

  export const MAPPING = [
    'Ster',
    'Copper',
    'PGIUD',
    'Implant',
    'Combined',
    'Prophil',
    'Patch',
    'Ring',
    'Injection',
    'MCondoms',
    'FCondoms',
    'APPS',
    'Spermicide',
    'Withdrawal',
    'Diaphragm',
  ]

const RES = {

  Ster : {
    id:          0,
    header : 'Sterilization',
    img:     'images/ster.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Sterilization-Information-Sheet.pdf',
    blurb:   'Sterilization includes tubal sterilization for patients with female reproductive organs or a vasectomy for those with male reproductive organs. Tubal sterilization is an outpatient surgical procedure that involves removing or cutting the fallopian tubes so that an egg and sperm cannot meet. Vasectomy is a short clinic procedure that involves cutting the vas deferens so that sperm is not released into the semen. Side effects are minimal and include some pain and recovery time after the procedure. Sterilization is over 99% effective at preventing pregnancy. Sterilization is only performed once, and it is permanent and irreversible. Sterilization must be performed by a physician. ',
    extra: {

    }
  },

  Copper : {
    id:          1,
    header : 'Copper IUD',
    img:     'images/copper.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Copper-IUD-Information-Sheet.pdf',
    blurb:   'An IUD (intrauterine device) is a T-shaped piece of plastic that is inserted into the uterus during a pelvic exam. The copper IUD has copper wrapped around the frame, which destroys sperm and prevents sperm from reaching the egg. Side effects include cramping during periods and possibly longer or heavier periods. Copper IUDs are over 99% effective at preventing pregnancy. They are fully reversible with no effect on fertility. Copper IUDs can last up to 12 years, and can be used by patients of any age or previous pregnancy status. All IUDs must be inserted and removed by a clinician.',
  },

  PGIUD : {
    id:          2,
    header : 'Hormonal IUD',
    img:     'images/piud.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Hormonal-IUD-Information-Sheet.pdf',
    blurb:   'An IUD (intrauterine device) is a T-shaped piece of plastic that is inserted into the uterus during a pelvic exam. The hormonal IUD contains progesterone, which thickens cervical mucus and prevents sperm from entering the uterus. The progesterone also thins the uterine lining to decrease menstrual bleeding. Side effects include initial irregular bleeding and cramping, and then lighter periods or no periods at all. Hormonal IUDs are over 99% effective at preventing pregnancy. They are fully reversible with no effect on fertility. Hormonal IUDs can last up to 3-8 years, depending on the type, and can be used by women of any age or previous pregnancy status. All IUDs must be inserted and removed by a clinician.',
  },

  Implant : {
    id:          3,
    header : 'Implant',
    img:     'images/implant.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Implant-Information-Sheet.pdf',
    blurb:   'An contraceptive implant is a small plastic rod that is inserted under the skin in the upper arm. The implant has progesterone, which prevents the release of an egg (ovulation) and thickens cervical mucus to prevent sperm from entering the uterus. Side effects include potential irregular bleeding, frequent bleeding, or no bleeding at all. The implant is over 99% effective at preventing pregnancy, and is fully reversible with no effect on fertility. The implant can last up to 4-5 years. All implants must be inserted and removed by a clinician.',
  },

  Combined : {
    id:          4,
    header : 'Combined Pills',
    img:     'images/combined.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Combined-Pills-Information-Sheet.pdf',
    blurb:   'Combined hormonal pills have estrogen and progesterone, and there are many different types. The hormones prevent release of an egg (ovulation) and thicken cervical mucus to prevent sperm from entering the uterus. The pill needs to be taken by mouth every single day. Menstrual effects include lighter, more regular, and less painful periods, with the ability to skip periods. Some people experience nausea, headaches, breast tenderness, and bloating on the pill. Combined hormonal pills are 93% effective at preventing pregnancy with typical use. That number increases to 99% with perfect use. All birth control pills must be prescribed by a clinician. The pill can be stopped at any time with no effect on fertility.',
    extra: {
      acne: "acne",
      pcos: "pcos"
    }
  },

  Prophil : {
    id:          5,
    header : 'Progestin Only Pills',
    img:     'images/prophil.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Progestin-Pills-Information-Sheet.pdf',
    blurb:   'Progestin-only pills have progesterone, which thickens cervical mucus to prevent sperm from entering the uterus. The pill needs to be taken by mouth at the same time every single day. Side effects include lighter and less painful periods. Some people experience spotting between periods. Progestin-only pills are 93% effective at preventing pregnancy with typical use. That number increases to 99% with perfect use. All birth control pills must be prescribed by a clinician. The pill can be stopped at any time with no effect on fertility.',
  },

  Patch : {
    id:          6,
    header : 'Patch',
    img:     'images/patch.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Patch-Information-Sheet.pdf',
    blurb:   'The patch is a 2x2 inch adhesive square that is placed on the skin. It contains estrogen and progesterone, which prevent release of an egg (ovulation) and thicken cervical mucus to prevent sperm from entering the uterus. The patch can be worn on the abdomen, buttock, back, or upper arm. It must be changed weekly. Menstrual effects often include lighter, more regular, and less painful periods, with the ability to skip periods. Some people experience nausea, headaches, breast tenderness, and bloating on the patch. The patch is 93% effective at preventing pregnancy with typical use. That number increases to 99% with perfect use. The patch must be prescribed by a clinician. The patch can be stopped at any time with no effect on fertility.',
  },

  Ring : {
    id:          7,
    header : 'Ring',
    img:     'images/ring.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Ring-Information-Sheet.pdf',
    blurb:   'The ring is a small, flexible plastic ring inserted into the vagina that contains estrogen and progesterone. These hormones prevent release of an egg (ovulation) and thicken cervical mucus to prevent sperm from entering the uterus. The ring is self-inserted into the vagina and replaced each month. Menstrual effects often include lighter, more regular, and less painful periods, with the ability to skip periods. The ring is 93% effective at preventing pregnancy with typical use. That number increases to 99% with perfect use. The ring must be prescribed by a clinician. The ring can be stopped at any time with no effect on fertility.',
  },

  Injection : {
    id:          8,
    header : 'Injection',
    img:     'images/injection.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Shot-Information-Sheet.pdf',
    blurb:   'The shot contains progesterone, which prevents release of an egg (ovulation) and thickens cervical mucus to prevent sperm from entering the uterus. Side effects include initial irregular bleeding, and then usually no periods. The shot is 96% effective at preventing pregnancy with typical use. That number increases to 99% with perfect use. The shot must be given every 3 months, and it must be prescribed by a clinician. The shot can be given at a doctor’s office or administered at home by the patient. The shot can be stopped at any time, and it may take up to 10 months after the last dose for fertility to return.',
  },

  MCondoms : {
    id:          9,
    header : 'Condoms',
    img:     'images/condom.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Condoms-Information-Sheet.pdf',
    blurb:   'Condoms are made from different types of materials including latex, nitrile, or lambskin. There are external condoms that are worn on the penis, as well as internal condoms that are placed inside the vagina. Condoms serve as a barrier to prevent sperm from entering the vagina. A new condom must be used during every act of sexual intercourse. Side effects are rare, with a small risk of irritation or a possible allergic reaction to the material. Condoms are 79-87% effective at preventing pregnancy with typical use. That number increases to 95-98% with perfect use. Condoms are the only type of contraception that reduces the risk of sexually transmitted infections. Condoms have no effect on fertility.',
  },

  FCondoms : {
    id:          10,
    header : 'Female Condoms',
    img:     'images/condom.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Condoms-Information-Sheet.pdf',
    blurb:   'Condoms are made from different types of materials including latex, nitrile, or lambskin. There are external condoms that are worn on the penis, as well as internal condoms that are placed inside the vagina. Condoms serve as a barrier to prevent sperm from entering the vagina. A new condom must be used during every act of sexual intercourse. Side effects are rare, with a small risk of irritation or a possible allergic reaction to the material. Condoms are 79-87% effective at preventing pregnancy with typical use. That number increases to 95-98% with perfect use. Condoms are the only type of contraception that reduces the risk of sexually transmitted infections. Condoms have no effect on fertility.',
  },

  APPS : {
    id:          11,
    header : 'Fertility Awareness Methods',
    img:     'images/apps.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Fertility-Awareness-Methods-Information-Sheet.pdf',
    blurb:   'Fertility awareness-based methods rely on patients having a good understanding of their menstrual cycles. Rather than preventing pregnancy at any time like other contraceptive methods, fertility awareness predicts the days when someone is able to get pregnant so they can avoid sex or use another form of protection. Fertility awareness can involve calendar-based methods (requiring tracking cycles, sometimes with a phone app) or tracking daily body changes (like early morning temperature or cervical mucus). Fertility awareness methods are about 76-88% effective for new users at preventing pregnancy.',
  },

  Spermicide : {
    id:          12,
    header : 'Spermicide',
    img:     'images/spermicide.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Spermicide-Sponge-Information-Sheet.pdf',
    blurb:   'There are many vaginal products, including spermicide and and vaginal pH regulators. Spermicide comes in many different forms including gel, cream, foam, and sponge. The vaginal pH regulator comes as a cream. Both destroy sperm in the vagina so they can’t enter the uterus. All vaginal products must be inserted into the vagina before every act of sexual intercourse. Side effects include vaginal irritation or possible allergic reaction. Spermicide is 79% effective at preventing pregnancy and can be purchased over-the-counter. pH regulators are 86% effective at preventing pregnancy and require a prescription. These methods can be stopped at any time with no effect on fertility.',
  },

  Withdrawal : {
    id:          13,
    header : 'Withdrawal',
    img:     'images/withdrawal.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Pulling-Out-Information-Sheet.pdf',
    blurb:   'Withdrawal involves pulling the penis out of the vagina prior to ejaculation. Withdrawal decreases the amount of sperm in the vagina that can enter the uterus for fertilization. Withdrawal must occur with every act of penile-vaginal sex. There are no side effects. Withdrawal is 80% effective at preventing pregnancy with typical use. That number increases to 96% with perfect use, but perfect use is difficult to achieve. The withdrawal method can be stopped at any time, and there is no effect on fertility.',
  },

  Diaphragm : {
    id:          14,
    header : 'Diaphragm',
    img:     'images/diaphragm.png',
    linkto:  'https://picck.org/wp-content/uploads/2022/11/Diaphragm-and-Cervical-Cap-Information-Sheet.pdf',
    blurb:   'Diaphragms and cervical caps are made of flexible silicone or latex. They are inserted vaginally prior to each act of sex and create a barrier over the cervix to prevent sperm from entering the uterus. They can be used with spermicide, which is a chemical that destroys sperm. Diaphragms are 83% effective at preventing pregnancy. Cervical caps are 86% effective at preventing pregnancy in patients that have never been pregnant before and 71% effective in patients that have been pregnant before. Diaphragms and cervical caps must be fitted and prescribed by a clinician and need to be replaced every two years. Use can be discontinued at any time with no effect on fertility.',
  },
}




// Results ---------------------------------------------------------------------------------- /

export class ResultPage extends React.Component {
    constructor(props) {

      super(props);

      this.items = [

      ]

      this.state = {green: [], yellow: [], red: []};
    }

    componentDidMount() {
      console.log(this.props.results);
      window.scrollTo(0, 0);
    }

    render() {
      return (
        <div id="restop">
        <HeaderBox
          text=
            {"The following methods are medically-safe for you and align with your stated reproductive goals. " +
            "Options in green align the closest to your preferences. " +
            "Yellow represents options that may not be right for you, " +
            "but may be suitable if you changed one or two preferences. " +
            "Options in red are methods that you should AVOID based off your preferences. " +
            "Please consult your physician for more information about your options. "}
          title="Results" />

        <AccordionWrap
          title={"Recommended Options"}
          body={"Options in this section align the closest to your preferences. Click through the options to see more information"}
          variant={"success"}
          inRange={x => x >= 3}
          points={this.props.results.Points}
        />
        <AccordionWrap
          title={"Potential Options"}
          body={"Options in this section represent options that may or may not be right for you; but may be suitable if you changed one or two preferences. Click through the options to see more information"}
          variant={"warning"}
          inRange={x => x == 2}
          points={this.props.results.Points}
        />
        <AccordionWrap
          title={"Potential Options (requires further discussion)"}
          body={"Options in this section represent options that may or may not be right for you; but may be suitable if you changed one or two preferences. Options here may require further discussion to determine if it is suitable. Click through the options to see more information"}
          variant={"info"}
          inRange={x => x == 1}
          points={this.props.results.Points}
        />
        <AccordionWrap
          title={"Options to Avoid"}
          body={"Options in this section are options that should be AVOIDED. Click through the options to see more information"}
          variant={"danger"}
          inRange={x => x <= 0 && x > -999}
          points={this.props.results.Points}
        />

        <LinkBox to="/#top" linkName="Start Over" />
        </div>
      );
    }

  }