import 'bootstrap/dist/css/bootstrap.css';
import './output.css';
import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'

import { ResultPage, MAPPING } from './result'
import { Box, ButtonBox, Header, HeaderBox, LinkBox } from './components'
import { QBox, CBox } from './questionBox'

// Ugh globals :P
//
// Should probably switch this out for react-hooks :P
//
// Jason Truong (Sharkbait)

let RES = {
  "Points": [],
  "Extra": [],
};

// This will control the form checking
// Checks for NUM_Q responses
// (it's crude, I know)
const NUM_Q = 1;

// Global points and base points
let POINTS = [];
let BASE = Array(15).fill(4);

// Debug flag
const DEBUG = true;

let computeTotals = (points) => {
  
  // Sandbag a choice
  // FIXME: remove it later
  BASE[10] = -9999;

  // Results (tool) is directly mapped to indices
  // of reduced points matrix.
  // See result.js for exact mapping.
  // Mapping of points in questions need to match
  // this layout.

  // Magic

  // Append base points
  points.push(BASE);
  console.log("points:", points);

  // Transpose matrix..
  let transpose = points[0].map((_,i) => points.map(x => x[i]));

  // MapReduce to sum points
  let reduced = transpose.map(x => x.reduce((a,b) => a+b));

  console.log("REDUCED: ", reduced);
  return reduced;

  // One more transformation for pivot into our 3 bins
}





// Questions -------------------------------------------------------------------------------- /

class Q1 extends QBox {
  constructor(props) {
    super(props);

    this.state.name =
      "Q1";
    this.state.question =
      "1) Do you ever want to become pregnant in the future?";
    this.state.choices =
      ["Yes", "No"];
    this.state.points   = [
      [-99,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
    ]
  }
}

class Q2 extends QBox {
  constructor(props) {
    super(props);

    this.state.name =
      "Q2";
    this.state.question =
      "2) Do you have heavy and/or painful periods at baseline?";
    this.state.choices =
      ["Yes", "No"];
    this.state.points   = [
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper      |   Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID   |   |   Patch   |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |   |   Ring|   |   |   APPS|   |   |
        |   |   |   |   |   |   |   |   |   |   |   |   |   |   |        */
      [ 0, -2,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
    ]
  }
}

class Q3 extends QBox {
  constructor(props) {
    super(props);

    this.state.name =
      "Q3";
    this.state.question = (
      <div>
      <h2>3)  Do you have any of the following health conditions: </h2>
      <ul>
        <li>liver disease</li>
        <li>migranes with aura</li>
        <li>high blood pressure</li>
        <li>smoking cigarettes after the age of 35</li>
        <li>history of blood clot or other blood vessel disease</li>
        <li>diabetes with vascular complications</li>
      </ul>
      </div>
    );
    this.state.choices =
      ["Yes", "No"];
    this.state.points   = [
      /*
        Ster            Combined       Injection       Spermicide
        |   Copper      |   Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID   |   |   Patch   |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |   |   Ring|   |   |   APPS|   |   |
        |   |   |   |   |   |   |   |   |   |   |   |   |   |   |        */
      [ 0,  0,  0,  0, -99, 0,-99,-99,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
    ]
  }
}

class Q4 extends QBox {
  constructor(props) {
    super(props);

    this.state.name     = "Q4";
    this.state.question = this.question()
    this.state.choices  = [
      "Up to 1% chance of pregnancy",
      "Up to 7% chance of pregnancy",
      "Up to 15% chance of pregnancy",
      "Up to 25% chance of pregnancy",
    ]
    this.state.points   = [
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper      |   Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID   |   |   Patch   |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |   |   Ring|   |   |   APPS|   |   |
        |   |   |   |   |   |   |   |   |   |   |   |   |   |   |        */
      [ 0,  0,  0,  0, -2, -2, -2, -2, -2, -3, -4, -3, -4, -4, -4],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0, -2, -3, -2, -3, -3, -3],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0, -3,  0, -3, -3, -3],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
    ]
  }

  question() {
    return (
      <h2>
        4) No birth control is 100% effective, so there is always a chance of
        becoming pregnant while using it. What is the <b>highest chance</b> of
        pregnancy each year that you would be willing to accept?
      </h2>
    );
  }
}

class Q5 extends QBox {
  constructor(props) {
    super(props);

    this.state.name = "Q5";
    this.state.question =
      "5) Are you willing to tolerate any changes in your menstrual bleeding (heavier or lighter)?";
    this.state.choices = ["Yes", "No"];
    this.state.points   = [
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper      |   Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID   |   |   Patch   |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |   |   Ring|   |   |   APPS|   |   |
        |   |   |   |   |   |   |   |   |   |   |   |   |   |   |        */
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
      [ 0, -2, -2, -2, -2, -2, -2, -2, -2,  0,  0,  0,  0,  0,  0],
    ]
  }
}

class Q5b extends CBox {
  constructor(props) {
    super(props);

    this.state.name = "Q5b";
    this.state.question =
      "5b) If you answered yes above, which of the following changes would "+
      "you tolerate";
    this.state.choices =
      [
        "Heavier",
        "Lighter",
        "Potentially Irregular",
        "Little to no bleeding at all",
      ];
    this.state.points   = [
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper      |   Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID   |   |   Patch   |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |   |   Ring|   |   |   APPS|   |   |
        |   |   |   |   |   |   |   |   |   |   |   |   |   |   |        */
      [ 0,  2,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  2,  2,  2,  2,  2,  2,  2,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  2,  2,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  2,  2,  0,  0,  0,  0,  2,  0,  0,  0,  0,  0,  0],
    ]
    this.state.basePoints = [
        0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0
    ];
  }
}

class Q6 extends QBox {
  constructor(props) {
    super(props);

    this.state.name = "Q6";
    this.state.question =
      "6) How often would you be willing to take or monitor your birth control?";
    this.state.choices =
      [
        "Never again",
        "Up to once a year",
        "Up to once a month",
        "Up to once a week",
        "Up to once a day",
      ];
    this.state.points   = [
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper       |  Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID    |  |  Patch    |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |  |    Ring|   |   |   APPS|   |   |
        |   |   |   |    |  |  |    |   |   |   |   |   |   |   |        */
      [ 0, -1, -1,  1, -4, -4, -4, -4,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  0,  0, -1, -1, -1, -1, -1,  0,  0, -1,  0,  0,  0],
      [ 0,  0,  0,  0, -1, -1, -1,  0,  0,  0,  0, -1,  0,  0,  0],
      [ 0,  0,  0,  0, -1, -1,  0,  0,  0,  0,  0, -1,  0,  0,  0],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
    ]
  }
}

class Q7 extends QBox {
  constructor(props) {
    super(props);

    this.state.name = "Q7";
    this.state.question =
      "7) Are you interested in methods that need to be applied before every act of sex?";
    this.state.choices =
      [
        "Yes",
        "No",
      ];
    this.state.points   = [
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper       |  Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID    |  |  Patch    |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |  |    Ring|   |   |   APPS|   |   |
        |   |   |   |    |  |  |    |   |   |   |   |   |   |   |        */
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0],
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0, -1, -1,  0, -1,  0, -1],
    ]
  }
}

class Q8 extends CBox {
  constructor(props) {
    super(props);

    this.state.name = "Q8";
    this.state.question =
      "8) Do you have any of the following specific medical conditions?";
    this.state.choices =
      [
        "Lactation",
        "Polycystic Ovarian Syndrome",
        "Need to be able to hide the method from others",
        "Depression",
        "Acne",
        "Plans for pregnancy within the next year",
        "Unreliable insurance coverage",
        "Fear of weight gain",
      ];
    this.state.points = [
      /* NO POINTS */
      /*
        Ster             Combined       Injection       Spermicide
        |   Copper      |   Prophil     |   MCondoms    |   Withdrawal
        |   |   PGUID   |   |   Patch   |   |   FCondoms|   |   Diaphragm
        |   |   |   Implant |   |   Ring|   |   |   APPS|   |   |
        |   |   |   |   |   |   |   |   |   |   |   |   |   |   |   */
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Lactation
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Polycystic         ["pcos"]
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Hide
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Depression
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Acne               ["acne"]
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Pregnancy in year
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Insurance
      [ 0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Weight gain
      // [ 0,  0,  0,  0,  2,  0,  2,  2,  0,  0,  0,  0,  0,  0,  0], // Lactation
      // [ 0,  0, -2,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0], // Polycystic
      // [ 0, -2, -2,  0,  0,  0,  0,  0, -2,  0,  0,  0,  0,  0,  0], // Hide
      // [ 0,  0,  0,  0,  0,  0,  0,  0,  2,  0,  0,  0,  0,  0,  0], // Depression
      // [ 0,  0,  0,  0,  2,  0,  2,  2,  0,  0,  0,  0,  0,  0,  0], // Acne
      // [ 0,  0,  0,  0,  0,  0,  0,  0,  2,  0,  0,  0,  0,  0,  0], // Pregnancy in year
      // [ 0, -1, -1,  0,  0,  0,  0,  0, -1,  0,  0,  0,  0,  0,  0], // Insurance
      // [ 0,  0,  0,  0,  0,  0,  0,  0,  2,  0,  0,  0,  0,  0,  0], // Weight gain
    ]
    this.state.extras = {
      lact: [ "something", "somethingelse"], // Lactation
      pcos: [ ], // Polycystic         ["pcos"]
      hide: [ ], // Hide
      depr: [ ], // Depression
      acne: [ ], // Acne               ["acne"]
      piny: [ ], // Pregnancy in year
      insu: [ ], // Insurance
      wgt:  [ ], // Weight gain
    }
    this.state.basePoints = [
        0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0,  0
    ];
  }

}




// Form -------------------------------------------------------------------------------- /

class TheForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validated : false,
      nav: false,
    };

  }

  render() {
    return (
      <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>
        {this.props.form}

        <ButtonBox onClick={this.handleSubmit} />
        { this.state.validated ? <Navigate to={"/hello"} /> : <></>}
      </Form>
    );
  }

  handleSubmit = _ => {
    // Validate form..
    if (this.props.checkCB()) {
      console.log("Computing...")
      RES['Points'] = computeTotals(POINTS);
      console.log("Redirecting to Results!")
      //RES["MEH"] = [0, 0, 0];
      this.setState({validated: true});
    } else {
      alert("Please answer all questions!");
    }
  }
}




class FormPage extends React.Component {
  constructor(props) {
    super(props);

    this.response = {};

    this.state = {q5b_vis: false};
  }

  render () {
    return (
      <div className='main-cont containder-md'>
        <TheForm form={this.render_form()} checkCB={this.check_form_and_collect_points}/>
      </div>
    )
  }

  render_form() {
    return (
        <div id="top">
          <HeaderBox
            title={<Header title="myACT"/>}
            text={
              "Welcome to myACT! " +
              "myACT is designed to be an easily accessible contraceptive tool. "}
            text2 = {
              "\nAnswer the questions to the best of your ability. "+
              "Submit the form to see recommendations based on your answers!"}
            />

          <Q1  cb={this.q_cb} />
          <Q2  cb={this.q_cb} />
          <Q3  cb={this.q_cb} />
          <Q4  cb={this.q_cb} />
          <Q5  cb={this.q_cb} />
          {this.state.q5b_vis && <Q5b cb={this.q_cb} />}
          <Q6  cb={this.q_cb} />
          <Q7  cb={this.q_cb} />
          <Q8  cb={this.q_cb}/>
        </div>
    );
  }

  // Question callback to record
  // selection, pointage, and
  // that the question is answered
  q_cb = (q) => {
    console.log(q);
    this.response[q.id] ??= {};
    let qq = this.response[q.id];
    qq.valid = true;
    qq.selection = q.sel;
    qq.pts = q.pts;



    // TODO: Make this more generic later
    // For now only 5b and 5a do this
    if (q.id === "Q5") {
      // Show extra question
      if (q.sel === "0")
        this.setState({"q5b_vis": true});
      // Hide extra question and clear out
      // its response
      else {
        if ("Q5b" in this.response) {
          delete this.response["Q5b"];
          console.log("DEL Q5b", this.response);
        }
        this.setState({"q5b_vis": false});
      }
    }

    // If debugging, compute and print out
    // current point totals
    //
    // This will collect points and compute
    // totals locally for debugging of points
    // based on current selections
    if (DEBUG) {
      let pp = [];
      this.collect(pp);
      let rp = computeTotals(pp);
      console.log("DEBUG CALCUATION OF CURRENT POINTS:");
      for( let [i, x] of rp.entries())
        console.log(MAPPING[i], x);
    }
  }

  // Helper to collect points
  collect = (points) => {
    Object.values(this.response).forEach(v => points.push(v.pts));
  }

  // Check form and dump pointage
  // to global (meh)
  //
  // This could be a lot more efficient (meh)
  check_form_and_collect_points = _ => {
    // Reset points..
    POINTS = [];

    // Check response
    if (Object.keys(this.response).length < NUM_Q ) return false;

    // Collect
    this.collect(POINTS);

    // Success! Let caller know
    return true;
  }
}




class Main extends React.Component {

  render() {
    return (
      <div className='main-cont container-md'>
        <Router className='backcolor'>
          <Routes>
            <Route exact path='/' element={<FormPage />}></Route>
            <Route exact path='/hello' element={<ResultPage results={RES} />}></Route>
          </Routes>
        </Router>
      </div>
    );
  }
}


// ========================================

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
