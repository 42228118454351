//import 'bootstrap/dist/css/bootstrap.css';
import './output.css';
import React, { Component, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Button, Form } from 'react-bootstrap'

export class Box extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <div className='questionbox'>
          <section className='d2'>
            {this.props.render_object}
          </section>
        </div>
      );
    }
  }

  export class HeaderBox extends React.Component {
    constructor(props) {
      super(props);
      this.state= {
        title: this.props.title,
        text:  this.props.text,
        text2: this.props.text2,
      };
    }

    render() {
      return (
        <div className='questionbox'>
          <section className='title'>
            <div className='lpad rpad'>
              <h1>
               {this.state.title}
              </h1>
            </div>
            <div className='lpad rpad'>
              <p>{this.state.text}</p>
            </div>
            {this.state.text2? this.render_text(this.state.text2): <></>}
          </section>
        </div>
      );
    }

    render_text(text) {
      return (
        <div className='lpad rpad'>
          <p>{text}</p>
        </div>
      );
    }

    static create_from_map(m) {
      return <HeaderBox title={<Header title={m.title}/>} />;
    }
  }

  export class LinkBox extends React.Component {

    render() {
      return (
        <div className='questionbox'>
          <section className='title'>
            <div className='lpad'>
              <Link to={this.props.to}>
                <h2>
                  {this.props.linkName}
                </h2>
              </Link>
            </div>
          </section>
        </div>
      );
    }
  }

  export class ButtonBox extends React.Component {
    render() {
      return (
        <div className='questionbox'>
            <section className='title'>
              <div className='sb-center'>
                <Button onClick={this.props.onClick}>
                  <h2 Style="margin: 0.5em;">
                    { "See Recommendations" }
                  </h2>
                </Button>
              </div>
            </section>
          </div>
        );
    }
  }

export class Header extends React.Component {
    render () {
      return (
        <div>
          <h1>{this.props.title}</h1>
        </div>
      );
    }
}