import 'bootstrap/dist/css/bootstrap.css';
import './output.css';
import React, { Component } from 'react';


export class CBox extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        'choices'  : this.props.choices,
        'question' : this.props.question,
        'name'     : this.props.name,
        'valid'    : false,
        'checks'   : {},
      }
    }

    on_check = evt => {
      console.log("cb_id:", evt.target.id);
      const id = evt.target.id.split('-')[1]

      this.state.checks[id] ??= false;                    // nullish set default
      this.state.checks[id]   = !this.state.checks[id];   // toggle

      console.log("checks: ", this.state.checks)
      // Compute point total to send back
      // Base initial from size of first set of points
      // (Should all be the same)

      // Base points
      let totalPoints = this.state.basePoints;

      Object.keys(this.state.checks)
        .filter(x => this.state.checks[x])                                    // filter out only checked
        .forEach(k => {                                                       // loop over checked options and collect points
          totalPoints = this.state.points[k].map((x,i) => x+totalPoints[i]);  // forEach checked option -> map points from that option
        })

      console.log("ret points: ", totalPoints)


      // Extras
      let extras = []

      // Check for extras
      if (this.state.extras) {
        console.log("extras: ", this.state.extras);
        Object.keys(this.state.checks)
          .filter(x => this.state.checks[x])                                    // filter out only checked
          .forEach(k => {                                                       // loop over checked options and collect extras
            extras.push(k);  // forEach checked option -> map points from that option
          })

        console.log("extra: ", extras);
      }

      // Make the callback back up
      this.props.cb({
        'id': this.state.name,
        'valid': true,
        'sel': Object.keys(this.state.checks).filter(x => this.state.checks[x]),
        'pts': totalPoints,
      })
    }

    render() {
      return (
        <div className='questionbox'>
          <section className='d2'>
            <div>
              <h2>{this.state.question}</h2>
              <br></br>
              <h2>(Please select all that may apply)</h2>
            </div>

            <div className="cont">
              {this.render_checks()}
            </div>

          </section>
        </div>
      );
    }

    render_checks() {
      const name = this.state.name;
      const citems = this.state.choices.map((c,i) =>
        <div>
          <input
            type = "checkbox"
            className = "hidden"
            id  = {name+"-"+i}
            key = {name+"-"+c}
            onChange={this.on_check}></input>
          <label for={name+"-"+i} className="label font">{c}</label>
        </div>
      );
      return citems;
    }
  }



  export class QBox extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        'choices'  : this.props.choices,
        'question' : this.props.question,
        'name'     : this.props.name,
        'valid'    : false,
      }
    }

    render_choices() {

        const name = this.state.name;
        const citems = this.state.choices.map((cstr,i) =>
          <div>
            <input type="radio" name={name} className="hidden"
              id  = {name+"-"+i}
              key = {name+"-"+cstr}
              onChange={this.on_radio_change}></input>
            <label for={name+"-"+i} className="label font">{cstr}</label>
          </div>
        )

        return citems;
    }

    on_radio_change = (event) => {
      console.log(event.target.id);
      this.state.valid = true;
      this.props.cb({
        'id': this.state.name,
        'valid': this.state.valid,
        'sel': event.target.id.split('-')[1],
        'pts': this.state.points[event.target.id.split('-')[1]],
      })
    }

    render() {
      return (
        <div className='questionbox'>
          <section className='d2'>
            <div>
              <h2>{this.state.question}</h2>
            </div>

            <div className="cont">
              {this.render_choices()}
            </div>

          </section>
        </div>
      );
    }

    static create_from_map(map) {
      return  <QBox name={map.name} question={map.q} choices={map.choices} />
    }
  }